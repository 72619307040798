<template>
  <div class="vertical-center justify-content-center">
    <!-- Page content -->
    <b-container class="pb-5">
      <b-row class="justify-content-center">
        <b-col lg="5" md="7">
          <b-card no-body class="bg-white border-0 mb-0">
            
            <b-card-body class="px-lg-4 py-lg-4">
               <div class="text-muted">
                  <img src="@/assets/images/logo/Logo_nieuw_RGB.svg" class="app-local-image-logo" right height="130px" alt="HappyOne" />               
              </div>
               <ValidationObserver ref="form">
              <div v-if="!success">

               <app-password v-model="password" class="mb-2" @enter="register" :validatorName="trans('auth-password',278)"  :label="trans('auth-password',278)"/>
               <app-password v-model="password_confirmation" @enter="register" :validatorName="trans('auth-password',278)"  :label="trans('auth-confirm-password',278)"/>
                 <ValidationObserver ref="custom">
                <app-input v-model="error" type="hidden"  :placeholder="trans('auth-password',278)" validatorRules="required" :validatorCustomMessage="{ required: trans('auth-password-not-same',279) }"/>
                </ValidationObserver>
              </div>
               </ValidationObserver>
               <div v-if="success">
                    <span class="app-local-success">{{trans('account-created',279)}}</span>
                </div>
              <div class="text-center">
                   <app-button type="primary" class="my-4"  v-if="!success" :loading="loading" :block="true" size="md" @click="register">{{trans('register',280)}}</app-button>
                    <app-button type="primary" class="my-4" v-if="success" :loading="loading" :block="true" size="md" @click="goToLogin">{{trans('back-to-login',285)}}</app-button>
              </div>
              <b-col class="text-center" cols="12">
              </b-col>
            </b-card-body>
          </b-card>
          
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>

import axios from "@axios";
import {ValidationObserver} from "vee-validate";
import AppPassword from "@core/components/AppPassword.vue"
import AppAlerts from '@core/scripts/AppAlerts';

  export default {
    
    components: {
        AppPassword,
        ValidationObserver
    },

    watch: {
        "password":{
            handler: function(val) {
                if(val != undefined &&  this.error == ''){
                  this.error= 'false';
                  this.$refs.custom.validate();
                }
                
            }
        },
          "password_confirmation":{
            handler: function(val) {
                if(val != undefined &&  this.error == ''){
                  this.error= 'false';
                  this.$refs.custom.validate();
                }
                
            },
        }
    },

    data() {
      return {
        password_confirmation: '',
        password: '',
        token: this.$route.params.token,
        error: 'false',
        success: false,
        rememberMe: false,
        loading: false,
        alertClass: new AppAlerts()
      };
    },
    methods: {
        register() {
        
            if(this.password_confirmation != this.password){
            this.error='';
            this.$refs.custom.validate()
            }
            this.$refs.form.validate().then(async (result) => {
                if (!result) {
                    return false;
                }else{
                    if(this.password_confirmation != this.password){
                        this.error='';
                        await this.$nextTick();
                        this.$refs.custom.validate()
                        return;
                    }
                    this.loading = true;
                    axios.post("authorization/resetPassword", {
                        password: this.password,
                        password_confirmation: this.password_confirmation,
                        token: this.token
                    })
                    .then(() => {
                        this.success = true;
                    })
                    .catch((error) => {
                        console.error(`error during request: ${error}`);
                    }).finally(() => {
                        this.loading = false;
                    }); 
                }
            });
        },

        goToLogin(){
            this.$router.push({ name: "Login"});
        }
    }
};
</script>

<style scoped>
.vertical-center {
  min-height: calc(100% -64px); 
  min-height: calc(100vh - 64px); 


  display: flex;
  align-items: center;
}

.app-local-image-logo{
  margin-left: -20px;
}

.app-local-success{
    font-size:12px;
    font-weight: 800;
    color: #3b9d75;
    background-color: #edfdf3;
    padding:6px;
}
</style>
